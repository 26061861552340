import baseUtil from "../js/utils.js"

// (本地)商品物流列表

export const getExpressList = (data) => baseUtil.sendRequest({
	url: '/LogisticsOrder/getList',
	...data
})

// 取(本地)物流订单详细信息
export const getOrderInfo = (data) => baseUtil.sendRequest({
	url: '/LogisticsOrder/getOrderInfo',
	...data
})

// 修改(重新选择)物流订单收货地址
export const setAddress = (data) => baseUtil.sendRequest({
	url: '/LogisticsOrder/setAddress',
	...data
})

// 订单换货
export const exchangeCommit = (data) => baseUtil.sendRequest({
	url: '/OrderExchange/exchangeCommit',
	...data
})

// 商品物流列表(待发货)
export const getWaitList = (data) => baseUtil.sendRequest({
	url: '/LogisticsOrder/getWaitList',
	...data
})

// 商品物流列表(已发货)
export const getSendList = (data) => baseUtil.sendRequest({
	url: '/LogisticsOrder/getSendList',
	...data
})

// 商品物流列表(换货中)
export const getExchangeList = (data) => baseUtil.sendRequest({
	url: '/LogisticsOrder/getExchangeList',
	...data
})