<!--
  * @desc:
  * @author: 肖
  * @create_date: 2021/6/5
  * @BSD
-->
<template>
  <van-nav-bar fixed left-arrow  @click-left="onClickLeft" :safe-area-inset-top="true">
    <div slot="left" class="left-wrap">
      <van-icon name="down" />
    </div>
    <template slot="title">
      <slot name="title"></slot>
    </template>
    <template slot="right">
      <slot name="right"></slot>
    </template>
  </van-nav-bar>
</template>

<script>
  export default {
    name: "top-bar-black",
    methods: {
      onClickLeft () {
        this.$router.go(-1)
      }
    }
  }
</script>

<style scoped lang="scss">
  $mainColor: #EE7F18;
.van-nav-bar{
  background: #ffffff;
  & ::v-deep .title-image {
    font-size: 36px;
    color: $mainColor;
    height: 36px;
    line-height: 36px;
    box-sizing: border-box;
  }
  & .left-wrap {
    width: 88px;
    height: 88px;
    background-image: linear-gradient(-45deg, #FFFFFF 0%, #F5F5F5 31%, #F5F5F5 100%);
    box-shadow: -8px -8px 20px 0 #FFFFFF, 16px 16px 24px 0 rgba(190,190,190,0.35);
    border-radius: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  & ::v-deep .van-nav-bar__content {
    padding: 84px 0 64px;
    height: 40px;
  }
 & ::v-deep .van-nav-bar__left {

   font-size: 40px;
    & .van-icon {

      color: #7C7A7A;
      transform: rotate(90deg);
    }
  }
  &::after {
    border: none;
  }
}

</style>
