import { render, staticRenderFns } from "./expressDetailDelivered.vue?vue&type=template&id=568d99ea&scoped=true&"
import script from "./expressDetailDelivered.vue?vue&type=script&lang=js&"
export * from "./expressDetailDelivered.vue?vue&type=script&lang=js&"
import style0 from "./expressDetailDelivered.vue?vue&type=style&index=0&id=568d99ea&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "568d99ea",
  null
  
)

export default component.exports